<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <polygon
      style="fill:#FED159;"
      points="468.021,271.352 512,271.352 512,162.746 0,162.746 0,271.352 43.979,271.352 "
    />
    <rect
      x="31.531"
      y="194.277"
      style="fill:#898989;"
      width="448.939"
      height="45.544"
    />
    <rect y="93.916" style="fill:#F6C454;" width="512" height="68.827" />
    <rect
      x="42.251"
      y="209.949"
      style="fill:#82DCC7;"
      width="425.758"
      height="208.126"
    />
    <g>
      <path
        style="fill:#74CBB4;"
        d="M87.156,418.084H42.255v-44.901C67.051,373.183,87.156,393.278,87.156,418.084z"
      />
      <path
        style="fill:#74CBB4;"
        d="M42.255,209.956h44.901c0,24.796-20.105,44.901-44.901,44.901
		C42.255,254.857,42.255,209.956,42.255,209.956z"
      />
      <path
        style="fill:#74CBB4;"
        d="M468.017,209.956v44.901c-24.806,0-44.901-20.105-44.901-44.901H468.017z"
      />
      <path
        style="fill:#74CBB4;"
        d="M468.017,373.183v44.901h-44.901C423.117,393.278,443.212,373.183,468.017,373.183z"
      />
      <ellipse
        style="fill:#74CBB4;"
        cx="255.13"
        cy="314.012"
        rx="56.522"
        ry="71.318"
      />
      <rect
        x="339.656"
        y="295"
        style="fill:#74CBB4;"
        width="102.475"
        height="15.847"
      />
      <rect
        x="63.913"
        y="295"
        style="fill:#74CBB4;"
        width="102.475"
        height="15.847"
      />
      <rect
        x="378.742"
        y="324.849"
        style="fill:#74CBB4;"
        width="63.388"
        height="15.847"
      />
      <rect
        x="63.913"
        y="324.849"
        style="fill:#74CBB4;"
        width="63.388"
        height="15.847"
      />
    </g>
    <polygon
      style="fill:#82DCC7;"
      points="247.213,291.184 263.061,291.184 288.421,291.184 288.421,275.336 263.061,275.336 
	263.061,257.761 247.213,257.761 247.213,275.336 221.852,275.336 221.852,321.94 272.573,321.94 272.573,336.849 263.061,336.849 
	247.213,336.849 221.852,336.849 221.852,352.696 247.213,352.696 247.213,370.272 263.061,370.272 263.061,352.696 
	288.421,352.696 288.421,306.093 237.699,306.093 237.699,291.184 "
    />
    <rect
      x="42.251"
      y="209.961"
      style="fill:#74CBB4;"
      width="425.758"
      height="25.086"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
